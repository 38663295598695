import React, { Component, useState } from "react";


function AboutUs() {


    return (
        <>
            <div id="main-wrapper">
                <div className="site-wrapper-reveal">
                    <div className="about-banner-wrap banner-space about-us-bg">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-9  ">
                                    <div className="about-banner-content text-center">
                                        <h1 className="mb-15 text-white">About AppCore Innovations</h1>
                                        <h5 className="font-weight--normal text-white">AppCore Innovations specializes in technological and IT-related services such as Web development , Mobile app development , UX/UI , Cloud services, E-Commerce, etc. </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*===========  feature-large-images-wrapper  Start =============*/}
                    <div className="feature-large-images-wrapper section-space--ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-center section-space--mb_60">
                                        <h6 className="section-sub-title mb-20">Our company</h6>
                                        <h3 className="heading">We run all kinds of IT services that <br /> vow your <span className="text-color-primary"> success</span></h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                </div>
                            </div>
                            <div className="cybersecurity-about-box section-space--pb_70">
                                <div className="row">
                                    <div className="col-lg-4 offset-lg-1">
                                        <div className="modern-number-01">
                                            <h2 className="heading  me-5"><span className="mark-text">15</span>Years’ Experience in IT</h2>
                                            <h5 className="heading mt-30">We’ve been triumphing all these <span className="text-color-primary"> 15 years. </span> Sacrifices are made up with success. </h5>

                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-1">
                                        <div className="cybersecurity-about-text">
                                            <div className="text">
                                                AppCore Innovations specializes in technological and IT-related services such as Web development , Mobile app development , UX/UI , Cloud services, E-Commerce, etc. We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</div>

                                            {/* <div className="button-text">
                                                <a href="#" className="btn-text">
                                                    Discover now
                                                    <span className="button-icon ml-1">
                                                        <i className="fas fa-arrow-right" />
                                                    </span>
                                                </a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feature-images__six">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-06">
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <div className="default-image">
                                                                <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-01-120x120.webp" alt />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-bs-parent="#accordion">
                                                                <p>Through the collaboration with customers in discussing needs and demand, we're able to attain mutual understanding, gain customer trust to offer appropriate advice, and bring about suggestions on suitable technology to transform your business. </p>
                                                            </div>
                                                            <a className="box-images-arrow">
                                                                <span className="button-text">How can we help your business?</span>

                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-06">
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <div className="default-image">
                                                                <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp" alt />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="card-body">
                                                                <p>AppCore Innovations takes into consideration every little detail to make sure the system run smoothly and responsively. Mitech employs a new technique called Minified Technology for securing customers' database &amp; building up highly confidential firewalls. </p>
                                                            </div>
                                                            <a className="box-images-arrow">
                                                                <span className="button-text">What are the advantages of AppCore Innovations?</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-06">
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <div className="default-image">
                                                                <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp" alt />
                                                            </div>
                                                        </div>
                                                        <div className="content">
                                                            <div className="card-body" style={{ marginBottom: 24 }}>
                                                                <p>We reduce redundant complex calculations and lengthy erroneous code texts with simpler ones to ensure Mitech would run seamlessly and the design is reserved in its best form when viewed from a wide range of mobile devices &amp; browsers. </p>
                                                            </div>
                                                            <a className="box-images-arrow">
                                                                <span className="button-text">How working process is simplified?</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="section-under-heading text-center section-space--mt_60">Challenges are just opportunities in disguise. </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*===========  feature-large-images-wrapper  End =============*/}
                    {/*========= About Resources Wrapper Start ===========*/}
                    <div className="about-resources-wrapper">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-lg-6 order-2 order-lg-1">
                                    <div className="resources-left-box">
                                        <div className="resources-inner">
                                            <h6 className="sub-title mb-20">Resources</h6>
                                            <h3 className="heading">Get a copy of brochure on <span className="text-color-primary"> Brand New IT Tech.</span></h3>
                                            <div className="button mt-30">
                                                <a href="#" className="ht-btn ht-btn-md">Download now (3MB)</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 order-1 order-lg-2">
                                    <div className="resources-right-box">
                                        <div className="resources-right-inner text-center">
                                            <div className="resources-images">
                                                <img className="img-fluid" src="assets/images/banners/logo.png" alt />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*========= About Resources Wrapper End ===========*/}
                    {/*========= About Delivering Wrapper Start ===========*/}
                    <div className="about-delivering-wrapper section-space--ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-wrap text-center section-space--mb_20">
                                        <h3 className="heading">We are dedicated to delivering <br /> the highest quality solutions</h3>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="delivering-optimal-wrap">
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title"> Cutting-Edge Software Development</h5>
                                                <div className="desc">We design and deliver customized software solutions that enhance performance, efficiency, and reliability to meet your precise business needs.</div>
                                            </div>
                                        </div>
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title"> Excellence in Quality Assurance </h5>
                                                <div className="desc">Our rigorous quality assurance protocols ensure your software is dependable, secure, and consistently meets the highest standards.</div>
                                            </div>
                                        </div>
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title">Highly Professional Teams </h5>
                                                <div className="desc">Our team of skilled and formally trained experts brings exceptional knowledge and innovative solutions to every software development challenge.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="delivering-optimal-wrap">
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title">Ongoing Product Enhancement</h5>
                                                <div className="desc">We continuously refine and advance software products through thorough testing and iterative improvements, ensuring sustained excellence. </div>
                                            </div>
                                        </div>
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title">Seamless Infrastructure Integration </h5>
                                                <div className="desc">Adopting a holistic approach to integrate and modernize your software infrastructure, facilitating smooth technological transitions and advancements.</div>
                                            </div>
                                        </div>
                                        <div className="list-item">
                                            <div className="marker" />
                                            <div className="title-wrap">
                                                <h5 className="title">Advanced Information Security </h5>
                                                <div className="desc">We implement cutting-edge security measures to protect your software and data, addressing critical security concerns with the highest standards and OWAPS security standard.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*=========== fun fact Wrapper Start ==========*/}
                    <div className="fun-fact-wrapper bg-theme-default section-space--pb_30 section-space--pt_60">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-3 col-sm-6 wow move-up">
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">10+</div>
                                        <h6 className="fun-fact__text">Happy clients</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up">
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">10+</div>
                                        <h6 className="fun-fact__text">Finished projects</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up">
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">30+</div>
                                        <h6 className="fun-fact__text">Skilled Experts</h6>
                                    </div>
                                </div>
                                <div className="col-md-3 col-sm-6 wow move-up">
                                    <div className="fun-fact--two text-center">
                                        <div className="fun-fact__count counter">20</div>
                                        <h6 className="fun-fact__text">Media Posts</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*=========== fun fact Wrapper End ==========*/}
                    {/*====================  testimonial section ====================*/}
                    <div className="testimonial-slider-area section-space--pt_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-wrap text-center section-space--mb_40">
                                        <h6 className="section-sub-title mb-20">Testimonials</h6>
                                        <h3 className="heading">What do people praise about <span className="text-color-primary"> AppCore Innovations?</span></h3>
                                    </div>
                                    <div className="testimonial-slider">
                                        <div className="swiper-container testimonial-slider__container">
                                            <div className="swiper-wrapper testimonial-slider__wrapper">
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__one wow move-up">
                                                        <div className="testimonial-slider--info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-02-90x90.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <div className="testimonial-rating">
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                </div>
                                                                <div className="author-info">
                                                                    <h6 className="name">Mahesh Gurjar</h6>
                                                                    <span className="designation">Co-Founder - MG Infosoft</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-slider__text">AppCore Innovations transformed our business with a custom software solution. The project was on time, within budget, and their support was exceptional. Highly recommended!.</div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__one wow move-up">
                                                        <div className="testimonial-slider--info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-03-90x90.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <div className="testimonial-rating">
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                </div>
                                                                <div className="author-info">
                                                                    <h6 className="name">Sandip Suthar</h6>
                                                                    <span className="designation">Operational Head</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-slider__text">AppCore Innovations exceeded our expectations with a web application that improved our operations. The team was professional, responsive, and great to work with.</div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__one wow move-up">
                                                        <div className="testimonial-slider--info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-04-90x90.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <div className="testimonial-rating">
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                </div>
                                                                <div className="author-info">
                                                                    <h6 className="name">Pujan Bhavasar</h6>
                                                                    <span className="designation">Shree Lakshi Narayan Food Industry - Director</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-slider__text">AppCore Innovations delivered a reliable solution that streamlined our processes and boosted productivity. We're very pleased with the results.</div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__one wow move-up">
                                                        <div className="testimonial-slider--info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-01-90x90.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <div className="testimonial-rating">
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                    <span className="fa fa-star" />
                                                                </div>
                                                                <div className="author-info">
                                                                    <h6 className="name">Milan Joshi</h6>
                                                                    <span className="designation">Project Manager</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="testimonial-slider__text">AppCore Innovations' support was outstanding and made a big difference. We look forward to continuing our partnership.</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="swiper-pagination swiper-pagination-t01 section-space--mt_30" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*====================  End of testimonial section  ====================*/}
                    {/*====================  brand logo slider area ====================*/}
                    <div className="brand-logo-slider-area section-space--ptb_60">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* brand logo slider */}
                                    <div className="brand-logo-slider__container-area">
                                        <div className="swiper-container brand-logo-slider__container">
                                            <div className="swiper-wrapper brand-logo-slider__one">
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-01.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-01-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-02.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-02-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-03.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-03-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-04.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-04-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-05.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-05-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-06.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-06-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-07.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-07-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-08.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-08-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-09.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-09-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*====================  End of brand logo slider area  ====================*/}
                    {/*============ Contact Us Area Start =================*/}
                    <div className="contact-us-area infotechno-contact-us-bg section-space--pt_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="contact-title section-space--mb_50">
                                        {/* <h3 className="mb-20">Need a hand?</h3> */}
                                        <p className="sub-title">Reach out to the world’s most reliable IT services.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="contact-form-wrap">
                                        {/* <form id="contact-form" action="https://whizthemes.com/mail-php/jowel/mitech/php/services-mail.php" method="post"> */}
                                        <form className="contact-form" id="contact-form" action="assets/php/services-mail.php" method="post">
                                            <div className="contact-form__two">
                                                <div className="contact-input">
                                                    <div className="contact-inner">
                                                        <input name="con_name" type="text" placeholder="Name *" />
                                                    </div>
                                                    <div className="contact-inner">
                                                        <input name="con_email" type="email" placeholder="Email *" />
                                                    </div>
                                                </div>
                                                <div className="contact-select">
                                                    <div className="form-item contact-inner">
                                                        <span className="inquiry">
                                                            <select id="Visiting" name="Visiting">
                                                                <option disabled selected>Select Department to email</option>
                                                                <option value="Your inquiry about">Your inquiry about</option>
                                                                <option value="General Information Request">General Information Request</option>
                                                                <option value="Partner Relations">Partner Relations</option>
                                                                <option value="Careers">Careers</option>
                                                                <option value="Software Licencing">Software Licencing</option>
                                                            </select>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="contact-inner contact-message">
                                                    <textarea name="con_message" placeholder="Please describe what you need." defaultValue={""} />
                                                </div>
                                                <div className="comment-submit-btn">
                                                    <button className="ht-btn ht-btn-md" type="button">Get a free consultation</button>
                                                    <p className="form-messege" />
                                                </div>
                                                <br/>
                                                <br/>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="col-lg-5 ms-auto">
                                    <div className="contact-info-three text-left">
                                    <h6 className="heading font-weight--reguler call-us"><a href="tel:9998855249">Reach out now!</a></h6>
                                        {/* <h3 className="call-us"><a href="tel:123 567990">(+91) 999 885 524 9</a></h3> */}
                                        <div className="text">Start the collaboration with us while figuring out the best solution based on your needs.</div>
                                        {/* <div className="location-button mt-20">
                                            <a href="#" className="location-text-button"><span className="button-icon" /> <span className="button-text">View on Google map</span></a>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/*============ Contact Us Area End =================*/}
                </div>

            </div>
        </>
    );
}

export default AboutUs;
