import React from 'react';


const CookiesPolicy = () => {
    return (
        <>
            <div>
                {/* breadcrumb-area start */}
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb_box text-center">
                                    <h1 className="breadcrumb-title text-color-primary">Cookies Policy</h1>
                                    {/* breadcrumb-list start */}
                                    <ul className="breadcrumb-list">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active">Cookies Policy</li>
                                    </ul>
                                    {/* breadcrumb-list end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* breadcrumb-area end */}
                <div id="main-wrapper">
                    <div className="site-wrapper-reveal">
                        <div className="divider-wrapper section-space--ptb_90">
                            <div className="container">
                                <div className="row">

                                    <div className="col-lg-12 wow move-up">
                                        <div className="single-divider section-space--ptb_60 border-bottom">
                                            <div className="element-title mb-15">
                                                <h6>Cookies Policy</h6>
                                            </div>
                                            <div className="content">
                                                <p>AppCore Innovations uses cookies to improve your experience. Cookies are small data files saved on your device that help us recognize you on future visits. By using our site, you consent to our use of cookies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 wow move-up">
                                        <div className="single-divider section-space--ptb_60 border-bottom-dash">
                                            <div className="element-title mb-15">
                                                <h6>What does a cookie mean?</h6>
                                            </div>
                                            <div className="content">
                                                <p>A cookie is a small data file stored on your device by a website. It helps the site recognize you on future visits. By using our services, you consent to receiving cookies.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 wow move-up">
                                        <div className="single-divider border-bottom-thick section-space--ptb_60">
                                            <div className="element-title mb-15">
                                                <h6>Which are the cookies being used?</h6>
                                            </div>
                                            <div className="element-title mb-15">
                                                <h6>Google Analytics</h6>
                                            </div>
                                            <div className="content">
                                                <p>Google Analytics (GA) is most commonly used to track user communication. This data helps us to know the details about visitors on our website, what they are searching for, which web pages they are focusing on and their entire roadmap to the website. This technology records user information such as your geolocation, device, browser, OS, IP address etc. but none of this information is passed on to us. As a third-party data processor, Google Analytics utilizes the cookies for further action.</p>
                                            </div>

                                            <div className="element-title mb-15" style={{ marginTop: 20 }}>
                                                <h6>Website Cookies</h6>
                                            </div>

                                            <div className="content">
                                                <p>Data collected via cookies and similar tools is treated as personal information under our privacy policy. For example, we use cookies to note your last visit date, which is stored for up to one year and then removed.</p>
                                            </div>
                                            <div className="element-title mb-15" style={{ marginTop: 20 }}>
                                                <h6>Internet-Based Promotions   </h6>
                                            </div>

                                            <div className="content">
                                                <p>We use Google-based sites and other partners to deliver personalized ads based on your interactions with our services. Cookies help us track which ads you view and click. We do not share your personal information with advertisers. However, third-party sites may use their own cookies for ad targeting and analytics, and their privacy policies are beyond our control. Please consult these third parties directly for more information.</p>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        {/*========== Call to Action Area Start ============*/}
                        <div className="cta-image-area_one section-space--ptb_80 cta-bg-image_one">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-xl-12 col-lg-7">
                                        <div className="cta-content md-text-center">
                                            <h3 className="heading text-white">Assess your business potentials and find opportunities <span className="text-color-secondary">for bigger success</span></h3>
                                        </div>
                                    </div>
                                    {/* <div className="col-xl-4 col-lg-5">
                                        <div className="cta-button-group--one text-center">
                                            <a href="#" className="btn btn--white btn-one"><span className="btn-icon me-2"><i className="far fa-comment-alt" /></span> Let's talk</a>
                                            <a href="#" className="btn btn--secondary  btn-two"><span className="btn-icon me-2"><i className="fas fa-info-circle" /></span> Get info</a>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/*========== Call to Action Area End ============*/}
                    </div>
                </div>
            </div>
        </>

    );
};

export default CookiesPolicy;