import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import Home from "./Components/home.component"
import MasterPage from "./Components/Common/MasterLayout"
import AboutUs from './Components/about_us'
import ContactUs from './Components/Contact_us';
import Careers from './Components/Careers';
import Technologies from './Components/Technologies';
import CookiesPolicy from './Components/CookiesPolicy'
import WhyChooseus from './Components/WhyChooseus'
import EmailSend from './Components/emailsend'

const App = () => {
  return (
    <>
      <BrowserRouter>
        <MasterPage>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="/technologies" element={<Technologies />} />
            <Route path="/cookies-policy" element={<CookiesPolicy />} />
            <Route path="/why-choose-us" element={<WhyChooseus />} />
            <Route path="/email-send" element={<EmailSend />} />
            <Route path="*" element={<div style={{ textAlign: 'center', fontSize: 20, fontWeight: 800 }}>Not Found</div>} />
          </Routes>
        </MasterPage>
      </BrowserRouter>

    </>

  );
};

export default App;

// import logo from './logo.svg';
// import './App.css';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import Home from "./Components/home.component"


// function App() {

//   AOS.init({
//     // Global settings:
//     disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
//     startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
//     initClassName: 'aos-init', // class applied after initialization
//     animatedClassName: 'aos-animate', // class applied on animation
//     useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
//     disableMutationObserver: false, // disables automatic mutations' detections (advanced)
//     debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
//     throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)


//     // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
//     offset: 120, // offset (in px) from the original trigger point
//     delay: 0, // values from 0 to 3000, with step 50ms
//     duration: 2000, // values from 0 to 3000, with step 50ms
//     easing: 'ease', // default easing for AOS animations
//     once: false, // whether animation should happen only once - while scrolling down
//     mirror: false, // whether elements should animate out while scrolling past them
//     anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
//   });

//   return (
//     <div className="App">
//       <Home />
//     </div >
//   );
// }

// export default App;
