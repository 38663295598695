import React from 'react';


const WhyChooseus = () => {
    return (
        <>

            <div>
                {/* breadcrumb-area start */}
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb_box text-center">
                                    <h2 className="breadcrumb-title">Why choose us</h2>
                                    {/* breadcrumb-list start */}
                                    <ul className="breadcrumb-list">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active">Why Choose us</li>
                                    </ul>
                                    {/* breadcrumb-list end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* breadcrumb-area end */}
                <div id="main-wrapper">
                    <div className="site-wrapper-reveal">
                        {/*===========  feature-large-images-wrapper  Start =============*/}
                        <div className="feature-large-images-wrapper section-space--ptb_100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        {/* section-title-wrap Start */}
                                        <div className="section-title-wrap text-center section-space--mb_30">
                                            <h3 className="heading mb-20">Preparing for your success, <br /> we provide <span className="text-color-primary">truly prominent IT solutions.</span> </h3>
                                            <p className="title-dec-text">The IT services that <b> AppCore Innocations </b> are running can be segmented by the type of skills employed to deliver the service (design, build, run). Technically, these fall into three main categories: business process services, application services, and infrastructure services.</p>
                                        </div>
                                        {/* section-title-wrap Start */}
                                    </div>
                                </div>
                                <div className="cybersecurity-about-box">
                                    <div className="row">
                                        <div className="col-lg-4 offset-lg-1">
                                            <div className="modern-number-01  mt-30">
                                                <h2 className="heading  me-5"><span className="mark-text">15</span>Years’ Experience in IT</h2>
                                                <h5 className="heading mt-30">We’ve been triumphing all these <span className="text-color-primary"> 15 years. </span> Sacrifices are made up with success. </h5>

                                            </div>
                                        </div>
                                        <div className="col-lg-6 offset-lg-1 wow move-up">
                                            <div className="progress-bar--one mt-30 section-space--mb_60">
                                                {/* Start Single Progress Charts */}
                                                <div className="progress-charts">
                                                    <h6 className="heading ">UI/UX</h6>
                                                    <div className="progress">
                                                        <div className="progress-bar wow fadeInLeft" data-wow-duration="0.5s" data-wow-delay=".3s" role="progressbar" style={{ width: '91%' }} aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}><span className="percent-label">91%</span></div>
                                                    </div>
                                                </div>
                                                {/* End Single Progress Charts */}
                                                {/* Start Single Progress Charts */}
                                                <div className="progress-charts">
                                                    <h6 className="heading ">Web App Development</h6>
                                                    <div className="progress">
                                                        <div className="progress-bar wow fadeInLeft" data-wow-duration="0.7s" data-wow-delay=".5s" role="progressbar" style={{ width: '82%' }} aria-valuenow={85} aria-valuemin={0} aria-valuemax={100}><span className="percent-label">82%</span></div>
                                                    </div>
                                                </div>
                                                {/* End Single Progress Charts */}
                                                {/* Start Single Progress Charts */}
                                                <div className="progress-charts ">
                                                    <h6 className="heading ">Mobile App Development</h6>
                                                    <div className="progress">
                                                        <div className="progress-bar wow fadeInLeft" data-wow-duration="0.8s" data-wow-delay=".8s" role="progressbar" style={{ width: '89%' }} aria-valuenow={70} aria-valuemin={0} aria-valuemax={100}><span className="percent-label">89%</span></div>
                                                    </div>
                                                </div>
                                                {/* End Single Progress Charts */}
                                                {/* Start Single Progress Charts */}
                                                <div className="progress-charts ">
                                                    <h6 className="heading ">Cloud</h6>
                                                    <div className="progress">
                                                        <div className="progress-bar wow fadeInLeft" data-wow-duration="0.9s" data-wow-delay=".9s" role="progressbar" style={{ width: '77%' }} aria-valuenow={90} aria-valuemin={0} aria-valuemax={100}><span className="percent-label">77%</span></div>
                                                    </div>
                                                </div>
                                                {/* End Single Progress Charts */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/*===========  feature-large-images-wrapper  Start =============*/}
                            <div id="main-wrapper">
                                <div className="site-wrapper-reveal">
                                    <div className="divider-wrapper section-space--ptb_90">
                                        <div className="container">
                                            <div className="row">

                                                <div className="col-lg-12 wow move-up">
                                                    <div className="single-divider section-space--ptb_60 border-bottom">
                                                        <div className="element-title mb-15">
                                                            <h6>Our Mission</h6>
                                                        </div>
                                                        <div className="content">
                                                            <p>AppCore Innovations LLP, Our mission is to empower businesses with cutting-edge software solutions that drive growth, efficiency, and innovation. We strive to stay at the forefront of technological advancements, providing our clients with solutions that keep them ahead of the competition.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 wow move-up">
                                                    <div className="single-divider section-space--ptb_60 border-bottom-dash">
                                                        <div className="element-title mb-15">
                                                            <h6>Our Services</h6>
                                                        </div>
                                                        <div className="content">
                                                            <p>AppCore Innovations LLP, Our team of seasoned professionals has a deep understanding of the latest technologies and industry trends, enabling us to deliver solutions that not only meet but exceed our clients' expectations. We specialize in providing customized solutions that are tailored to the specific needs and goals of each client, ensuring that they receive the maximum value from our services.</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 wow move-up">
                                                    <div className="single-divider section-space--ptb_60 border-bottom-dash">
                                                        <div className="element-title mb-15">
                                                            <h6>Our Vision</h6>
                                                        </div>
                                                        <div className="content">
                                                            <p>AppCore Innovations LLP, Our vision is to revolutionize the digital landscape by pioneering cutting-edge solutions that empower businesses and enrich lives. We strive to be at the forefront of technological advancement, delivering innovative applications and platforms that drive transformation and unlock new possibilities. By fostering a culture of creativity, collaboration, and excellence, we aim to shape the future of technology and create a world where every interaction is seamless, efficient.</p>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    {/*========== Call to Action Area Start ============*/}

                                    {/*========== Call to Action Area End ============*/}
                                </div>
                            </div>
                            {/*===========  feature-large-images-wrapper  End =============*/}
                        </div>
                        {/*===========  feature-large-images-wrapper  End =============*/}
                        {/*============ Contact Us Area Start =================*/}
                        {/* <div className="contact-us-area infotechno-contact-us-bg section-space--pt_100">
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <div className="image">
                                            <img className="img-fluid" src="assets/images/banners/home-cybersecurity-contact-bg-image.webp" alt />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 ms-auto">
                                        <div className="contact-info style-two text-left">
                                            <div className="contact-info-title-wrap text-center">
                                                <h3 className="heading  mb-10">4.9/5.0</h3>
                                                <div className="ht-star-rating lg-style">
                                                    <span className="fa fa-star" />
                                                    <span className="fa fa-star" />
                                                    <span className="fa fa-star" />
                                                    <span className="fa fa-star" />
                                                    <span className="fa fa-star" />
                                                </div>
                                                <p className="sub-text">by 700+ customers for 3200+ clients</p>
                                            </div>
                                            <div className="contact-list-item">
                                                <a href="tel:190068668" className="single-contact-list">
                                                    <div className="content-wrap">
                                                        <div className="content">
                                                            <div className="icon">
                                                                <span className="fas fa-phone" />
                                                            </div>
                                                            <div className="main-content">
                                                                <h6 className="heading">Call for advice now!</h6>
                                                                <div className="text">1900 68668</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                                <a href="mailto:hello@mitech.com" className="single-contact-list">
                                                    <div className="content-wrap">
                                                        <div className="content">
                                                            <div className="icon">
                                                                <span className="far fa-envelope" />
                                                            </div>
                                                            <div className="main-content">
                                                                <h6 className="heading">Say hello</h6>
                                                                <div className="text">hello@mitech.com</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        <div className="contact-us-area infotechno-contact-us-bg section-space--pt_100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="contact-title section-space--mb_50">
                                            {/* <h3 className="mb-20">Need a hand?</h3> */}
                                            <p className="sub-title">Reach out to the world’s most reliable IT services.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="contact-form-wrap">
                                            {/* <form id="contact-form" action="https://whizthemes.com/mail-php/jowel/mitech/php/services-mail.php" method="post"> */}
                                            <form className="contact-form" id="contact-form" action="assets/php/services-mail.php" method="post">
                                                <div className="contact-form__two">
                                                    <div className="contact-input">
                                                        <div className="contact-inner">
                                                            <input name="con_name" type="text" placeholder="Name *" />
                                                        </div>
                                                        <div className="contact-inner">
                                                            <input name="con_email" type="email" placeholder="Email *" />
                                                        </div>
                                                    </div>
                                                    <div className="contact-select">
                                                        <div className="form-item contact-inner">
                                                            <span className="inquiry">
                                                                <select id="Visiting" name="Visiting">
                                                                    <option disabled selected>Select Department to email</option>
                                                                    <option value="Your inquiry about">Your inquiry about</option>
                                                                    <option value="General Information Request">General Information Request</option>
                                                                    <option value="Partner Relations">Partner Relations</option>
                                                                    <option value="Careers">Careers</option>
                                                                    <option value="Software Licencing">Software Licencing</option>
                                                                </select>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="contact-inner contact-message">
                                                        <textarea name="con_message" placeholder="Please describe what you need." defaultValue={""} />
                                                    </div>
                                                    <div className="comment-submit-btn">
                                                        <button className="ht-btn ht-btn-md" type="button">Get a free consultation</button>
                                                        <p className="form-messege" />
                                                    </div>
                                                    <br />
                                                    <br />
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-lg-5 ms-auto">
                                        <div className="contact-info-three text-left">
                                            <h6 className="heading font-weight--reguler">Reach out now!</h6>
                                            <h3 className="call-us"><a href="tel:123 567990">(+91) 999 885 524 9</a></h3>
                                            <div className="text">Start the collaboration with us while figuring out the best solution based on your needs.</div>
                                            {/* <div className="location-button mt-20">
                                            <a href="#" className="location-text-button"><span className="button-icon" /> <span className="button-text">View on Google map</span></a>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*============ Contact Us Area End =================*/}
                    </div>

                </div>
            </div>



        </>

    );
};

export default WhyChooseus;