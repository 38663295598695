import React from "react";


function App() {

    return (

        <>
            <div id="main-wrapper">
                <div className="site-wrapper-reveal">
                    {/*============ Infotechno Hero Start ============*/}
                    <div className="infotechno-hero infotechno-bg">
                        <div className="container-fluid">
                            <div className="row align-items-center">{/*baseline*/}
                                <div className="col-lg-6 col-md-6">
                                    <div className="infotechno-hero-text  wow move-up" style={{ textAlign: "center" }}>
                                        {/* <h6>IT services &amp; Consulting </h6>
                                            <h1 className="font-weight--reguler mb-15">Facilitate All <br />  Custom Software Development</h1>
                                            <p>Well Exprieced Professional Software engineer teams.</p>
                                            <div className="hero-button  mt-30">
                                                <a href="#" className="ht-btn ht-btn-md">Get details</a>
                                            </div> */}

                                        <h6>IT Solutions For Easy Integration</h6>
                                        <h2 className="font-weight--reguler mb-15">Deliver <b> Innovative </b> </h2>
                                        <h2 className="font-weight--reguler mb-15"><b>IT Solutions </b>For <br />  Technologies Insights</h2>
                                        <p className="font-weight--reguler mb-15">ELEVANTING SOFTWARE INNOVATION.</p>
                                        <div className="hero-button  mt-30">
                                            {/* <a href="#" className="ht-btn ht-btn-md">Get details</a> */}

                                        </div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="infotechno-hero-inner-images">
                                        <div className="infotechno-inner-one">
                                            <img className="img-fluid" src="assets/images/hero/home-infotechno-main-slider-slide-01-image-01.webp" alt />
                                        </div>
                                        <div className="infotechno-inner-two  wow move-up">
                                            <img className="img-fluid" src="assets/images/hero/it-service2.png" alt />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*============ Infotechno Hero End ============*/}
                    {/*====================  brand logo slider area ====================*/}
                    <div className="brand-logo-slider-area section-space--ptb_60" style={{ paddingTop: 10, paddingBottom: 10 }}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* brand logo slider */}
                                    <div className="brand-logo-slider__container-area">
                                        <div className="swiper-container brand-logo-slider__container">
                                            <div className="swiper-wrapper brand-logo-slider__one">
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-01.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-01-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-02.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-02-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-03.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-03-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-04.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-04-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-05.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-05-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-06.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-06-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-07.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-07-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-08.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-08-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                                <div className="swiper-slide brand-logo brand-logo--slider">
                                                    <a href="#">
                                                        <div className="brand-logo__image">
                                                            <img src="assets/images/brand/mitech-client-logo-09.webp" className="img-fluid" alt />
                                                        </div>
                                                        <div className="brand-logo__image-hover">
                                                            <img src="assets/images/brand/mitech-client-logo-09-hover.webp" className="img-fluid" alt />
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*====================  End of brand logo slider area  ====================*/}
                    {/*===========  feature-images-wrapper  Start =============*/}
                    <div className="feature-images-wrapper bg-gray section-space--ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-center" >
                                        <h6 className="section-sub-title mb-20">Our services</h6>
                                        <h3 className="heading">For your very specific industry, <br /> we have <span className="text-color-primary"> highly-tailored IT solutions.</span></h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="feature-images__one">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01" >
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-02-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">UI/UX Design</h5>
                                                            <div className="text">Transform your digital presence with expert UI/UX design
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01" >
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-02-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">Front-end Development</h5>
                                                            <div className="text">Enhance your web apps with top-tier frontend expertise.
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01" >
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-03-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">Back-end Development</h5>
                                                            <div className="text">Utilize streamlined backend development for superior digital performance.
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01" >
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-01-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">Mobile App Development </h5>
                                                            <div className="text">Enhance your brand through our expert mobile app solutions.
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01">
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-02-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">E-Commerce Development</h5>
                                                            <div className="text">Specialized ecommerce solutions to expand your business and increase growth.
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                            <div className="col-lg-4 col-md-6 wow move-up">
                                                {/* ht-box-icon Start */}
                                                <div className="ht-box-images style-01">
                                                    <div className="image-box-wrap">
                                                        <div className="box-image">
                                                            <img className="img-fulid" src="assets/images/icons/mitech-box-image-style-01-image-03-100x108.webp" alt />
                                                        </div>
                                                        <div className="content">
                                                            <h5 className="heading">IOT App development</h5>
                                                            <div className="text">IOT solution for industry automation and cloud Integration.
                                                            </div>
                                                            <div className="circle-arrow">
                                                                <div className="middle-dot" />
                                                                <div className="middle-dot dot-2" />
                                                                <a href="#">
                                                                    <i className="fas fa-arrow-right" />
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* ht-box-icon End */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="section-under-heading text-center section-space--mt_60 section-space--pt_30">Challenges are just opportunities in disguise. <a href="#">Take the challenge!</a></div> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*===========  feature-images-wrapper  End =============*/}
                    {/* ============ Our Experience Wrapper Start =============== */}
                    <div className="section-space--ptb_100 infotechno-section-bg-01">
                        <div className="our-experience-wrapper">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-5" >
                                        <div className="section-title small-mb__40 tablet-mb__40">
                                            <h6 className="section-sub-title mb-20">Our company</h6>
                                            <h3 className="heading">We’ve been thriving in <span className="text-color-primary">2.5+ years</span></h3>
                                            <p className="text mt-20"><b> AppCore Innocations </b>specializes in technological and IT-related services such as Web Technologies, Mobile app, Cloud services, E-Commerce etc. We put a strong focus on the needs of your business to figure out solutions that best fits your demand and nail it.</p>
                                            {/* <div className="sider-title-button-box mt-30">
                                                <a href="#" className="ht-btn ht-btn-md">Join us now</a>
                                            </div> */}
                                        </div>
                                    </div>
                                    <div className="col-lg-5 offset-lg-2">
                                        <div className="ht-list style-auto-numbered-02">
                                            <div className="list-item" >
                                                <a className="link" >
                                                    <div className="list-header">
                                                        <div className="marker">
                                                            01
                                                        </div>
                                                        <div className="title-wrap">
                                                            <h6 className="title">How we can help your business? </h6>
                                                        </div>
                                                        <div className="title-wrap" style={{ marginLeft: 55 }}>
                                                            We’re a dynamic team dedicated to digital transformation, providing bespoke solutions to propel your business growth and efficiency.
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="list-item" style={{ marginTop: -50 }}>
                                                <a className="link">
                                                    <div className="list-header">
                                                        <div className="marker">
                                                            02
                                                        </div>
                                                        <div className="title-wrap">
                                                            <h6 className="title">Why become our partner? </h6>
                                                        </div>
                                                        <div className="title-wrap" style={{ marginLeft: 55 }}>
                                                            We are a vibrant team blending youthful innovation with deep industry expertise, staying ahead with the latest technologies to deliver top-notch solutions that drive your business forward
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <div className="list-item" style={{ marginTop: -50 }}>
                                                <a className="link" >
                                                    <div className="list-header">
                                                        <div className="marker"> 03</div>
                                                        <div className="title-wrap">
                                                            <h6 className="title"> What are the best of Appcore Innocations? </h6>
                                                        </div>
                                                        <div className="title-wrap" style={{ marginLeft: 55 }}>
                                                            We excel in delivering top-notch web app development, mobile app development, and IoT solutions for industry automation, among other services.
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*===========  feature-large-images-wrapper  Start =============*/}


                        <div className="feature-images-wrapper bg-gradient section-space--ptb_100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12" >
                                        {/* section-title-wrap Start */}
                                        {/* <div className="section-title-wrap text-center section-space--mb_30">
                                                <h6 className="section-sub-title mb-20">AppCore Innovations work process</h6>
                                                <h3 className="heading">Process We <span className="text-color-primary"> Follow</span></h3>
                                            </div> */}
                                        <div className="section-title-wrap text-center section-space--mb_30">
                                            <h6 className="section-sub-title mb-20">How we works</h6>
                                            <h3 className="heading">How it helps <span className="text-color-primary"> your
                                                business succeed</span></h3>
                                        </div>
                                        {/* section-title-wrap Start */}
                                    </div>
                                </div>
                                <div className="image-l-r-box">
                                    <div className="row">
                                        <div className="col image-box-area">
                                            <div className="row image-box-boder-box">
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09">
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-01-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">Requirement Gathering </h6>
                                                                <div className="text">Follow the first and foremost priority of gathering requirements, resources, and information to begin our project.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09">
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-02-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">UI/UX Design</h6>
                                                                <div className="text">We create catchy and charming designs with the latest tools of designing to make it a best user-friendly experience.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09">
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-03-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">Prototype</h6>
                                                                <div className="text">After designing, you will get your prototype, which will be sent ahead for the development process for the product.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09">
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-04-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">Development</h6>
                                                                <div className="text">Development of Mobile app/web/blockchain started using latest tools and technologies with transparency.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09" style={{ height: 314 }}>
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-05-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">Quality Assurance</h6>
                                                                <div className="text">Tronixinfo values quality and provides 100% bug free application with no compromisation in it.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                                <div className="col-lg-4 col-md-6 image-box-boder">
                                                    {/* ht-box-icon Start */}
                                                    <div className="ht-box-images style-09" style={{ height: 314 }}>
                                                        <div className="image-box-wrap">
                                                            <div className="box-image">
                                                                <img src="assets/images/icons/mitech-processing-service-image-06-100x104.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="content">
                                                                <h6 className="heading">Deployment</h6>
                                                                <div className="text">After trial and following all processes, your app is ready to launch on the App store or Play Store.
                                                                </div>
                                                                {/* <div className="more-arrow-link">
                                                                        <a href="#">
                                                                            Learn more <i className="fas fa-arrow-right" />
                                                                        </a>
                                                                    </div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* ht-box-icon End */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="talk-message-box-wrapper section-space--mt_80 small-mt__60">
                                    <div className="row">
                                        <div className="col-lg-15">
                                            <div className="talk-message-box ">
                                                <div className="message-icon">
                                                    <i className="far fa-comment-alt" />
                                                </div>
                                                <div className="talk-message-box-content ">
                                                    <h6 className="heading">Support & Maintenance</h6>
                                                    {/* <a href="#" className="ht-btn ht-btn-sm">Let's talk</a> */}
                                                </div>
                                                <div className="talk-message-box-content ">
                                                    <h8>Our company offers you all support and the team is always ready to answer every query after deployment.</h8>
                                                    {/* <a href="#" className="ht-btn ht-btn-sm">Let's talk</a> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/*===========  feature-large-images-wrapper  End =============*/}
                    </div>



                    {/* ============ Our Experience Wrapper End =============== */}
                    {/*=========== fun fact Wrapper Start ==========*/}
                    <div className="fun-fact-wrapper">
                        <div className="container">
                            <div className="fun-fact-grid-4">
                                <div className="grid-item  wow move-up">
                                    <div className="fun-fact--one text-center">
                                        <h5 className="fun-fact__title">Successfully work <br /> with </h5>
                                        <div className="fun-fact__count counter">10+</div>
                                        <span className="fun-fact__text">HAPPY CLIENTS</span>
                                    </div>
                                </div>
                                <div className="grid-item  wow move-up">
                                    <div className="fun-fact--one text-center">
                                        <h5 className="fun-fact__title">Successfully <br /> completed</h5>
                                        <div className="fun-fact__count counter">10+</div>
                                        <span className="fun-fact__text">Finished projects</span>
                                    </div>
                                </div>
                                <div className="grid-item  wow move-up">
                                    <div className="fun-fact--one text-center">
                                        <h5 className="fun-fact__title">Recruit more <br /> than</h5>
                                        <div className="fun-fact__count counter">30+</div>
                                        <span className="fun-fact__text">SKilled Experts</span>
                                    </div>
                                </div>
                                <div className="grid-item  wow move-up">
                                    <div className="fun-fact--one text-center">
                                        <h5 className="fun-fact__title">Blog <br /> update </h5>
                                        <div className="fun-fact__count counter">20</div>
                                        <span className="fun-fact__text">Media Posts</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*=========== fun fact Wrapper End ==========*/}
                    {/*=========== Infotechno Video Wrapper Start ==========*/}
                    <div className="infotechno-video-wrapper infotechno-video-ptb infotechno-video-bg1">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-10 col-11 offset-md-2 offset-1 offset-lg-6 ms-auto">
                                    <div className="video-content-wrap">
                                        <div className="vieeo-content-inner">
                                            <h2 className="heading">Share the joy of achieving glorious moments<br />&amp; climbing up the top.</h2>
                                            <div className="viewo-text-wrap">
                                                {/* <div className="video-popup infotech-video-box">
                                                        <a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="single-popup-wrap video-link">
                                                            <div className="video-content">
                                                                <div className="ht-popup-video video-button">
                                                                    <div className="video-mark">
                                                                        <div className="wave-pulse wave-pulse-1" />
                                                                        <div className="wave-pulse wave-pulse-2" />
                                                                    </div>
                                                                    <div className="video-button__two">
                                                                        <div className="video-play">
                                                                            <span className="video-play-icon" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="video-text">
                                                                    Watch video<br />intro
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*=========== Infotechno Video Wrapper End ==========*/}
                    {/*===========  Projects wrapper Start =============*/}
                    <div className="projects-wrapper projectinfotechno-bg section-space--ptb_100">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12" >
                                    {/* section-title-wrap Start */}
                                    <div className="section-title-wrap text-center section-space--mb_40">
                                        <h6 className="section-sub-title mb-20">Our Tech Stack</h6>
                                        <h3 className="heading">Technologies We <span className="text-color-primary"> Specialize In</span></h3>
                                    </div>
                                    {/* section-title-wrap Start */}
                                </div>
                            </div>

                            <div>
                                <div className="col-lg-12 ht-tab-wrap">
                                    <div className="row">
                                        <div className="col-12 text-center wow move-up ">
                                            <ul className="nav justify-content-center ht-tab-menu" role="tablist">
                                                <li className="tab__item nav-item active">
                                                    <a className="nav-link active" id="nav-tab1" data-bs-toggle="tab" href="#frontend-tab" role="tab" aria-selected="true">Front-End</a>
                                                </li>
                                                <li className="tab__item nav-item">
                                                    <a className="nav-link" id="nav-tab2" data-bs-toggle="tab" href="#backend-tab" role="tab" aria-selected="false">Backend</a>
                                                </li>
                                                <li className="tab__item nav-item ">
                                                    <a className="nav-link" id="nav-tab3" data-bs-toggle="tab" href="#mobile-tab" role="tab" aria-selected="false">Mobile</a>
                                                </li>
                                                <li className="tab__item nav-item ">
                                                    <a className="nav-link" id="nav-tab5" data-bs-toggle="tab" href="#cloud-tab" role="tab" aria-selected="false">Cloud</a>
                                                </li>
                                                <li className="tab__item nav-item ">
                                                    <a className="nav-link" id="nav-tab5" data-bs-toggle="tab" href="#database-tab" role="tab" aria-selected="false">Database</a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-content ht-tab__content wow move-up" style={{ textAlign: 'center' }}>
                                    <div className="tab-pane fade show active" id="frontend-tab" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_60">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="claim-grid-group ">
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-life-buoy" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">01</h6>
                                                                    {/* <h5 className="heading">Sound advice</h5> */}
                                                                    {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                                    <img src={"/assets/images/Symbol/nextjs.png"} aria-label="App Core" width={125} height={48} className="img-fluid" alt style={{ marginTop: 43 }} />
                                                                    <h5 style={{ marginTop: 43 }}><span className="text-color-primary sub-heading"> Next JS</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/react.svg"} aria-label="App Core" width={90} height={48} className="img-fluid" alt />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> React Js</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-five">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">03</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/vite.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" alt />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> ViteJS</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-world" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">04</h6>
                                                                    <img src={"/assets/images/Symbol/angular.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" alt />
                                                                    <h5 style={{ marginTop: 2 }}><span className="text-color-primary sub-heading">Angular</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-four">
                                                                <div className="icon">
                                                                    <span className="icon-basic-world" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">05</h6>
                                                                    <img src={"/assets/images/Symbol/js.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt />
                                                                    <h5 style={{ marginTop: 6 }}><span className="text-color-primary sub-heading"> Javascript</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-five">
                                                                <div className="icon">
                                                                    <span className="icon-basic-case" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">06</h6>
                                                                    <img src={"/assets/images/Symbol/jquery1.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 5 }} />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> Jquery</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">07</h6>
                                                                    <img src={"/assets/images/Symbol/ts.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> Type Scrtipt</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-four">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">08</h6>
                                                                    <img src={"/assets/images/Symbol/html.png"} aria-label="App Core" width={180} height={48} className="img-fluid" alt style={{ marginTop: 2 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">HTML</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">09</h6>
                                                                    <img src={"/assets/images/Symbol/css.png"} aria-label="App Core" width={85} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                                    <h5 style={{ marginTop: 0 }}><span className="text-color-primary sub-heading">CSS</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">10</h6>
                                                                    <img src={"/assets/images/Symbol/bootstrap.svg"} aria-label="App Core" width={120} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">Bootstrop</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">11</h6>
                                                                    <img src={"/assets/images/Symbol/tailwindcss.svg"} aria-label="App Core" width={130} height={48} className="img-fluid" alt style={{ marginTop: 15 }} />
                                                                    <h5 style={{ marginTop: 15 }}><span className="text-color-primary sub-heading">Tailwind CSS</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-four">
                                                                <div className="icon">
                                                                    <span className="icon-basic-lock" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">12</h6>
                                                                    <img src={"/assets/images/Symbol/figma.svg"} aria-label="App Core" width={130} height={48} className="img-fluid" alt style={{ marginTop: 30 }} />
                                                                    <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">Figma</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="backend-tab" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_60">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="claim-grid-group">
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-life-buoy" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">01</h6>
                                                                    {/* <h5 className="heading">Sound advice</h5> */}
                                                                    {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                                    <img src={"/assets/images/Symbol/dotnet.svg"} aria-label="App Core" width={95} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">.Net</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/csharp.svg"} aria-label="App Core" width={95} height={48} className="img-fluid" alt />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">C#</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-world" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">03</h6>
                                                                    <img src={"/assets/images/Symbol/nodejs.svg"} aria-label="App Core" width={120} height={48} className="img-fluid" style={{ marginTop: 20 }} alt />
                                                                    <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">Node Js</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-four">
                                                                <div className="icon">
                                                                    <span className="icon-basic-world" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">04</h6>
                                                                    <img src={"/assets/images/Symbol/express.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 41 }} alt />
                                                                    <h5 style={{ marginTop: 41 }}><span className="text-color-primary sub-heading">Express Js</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-five">
                                                                <div className="icon">
                                                                    <span className="icon-basic-case" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">05</h6>
                                                                    <img src={"/assets/images/Symbol/graphql.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 5 }} />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Graph QL</span></h5>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="mobile-tab" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_60">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="claim-grid-group">
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-life-buoy" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">01</h6>
                                                                    {/* <h5 className="heading">Sound advice</h5> */}
                                                                    {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                                    <img src={"/assets/images/Symbol/react-native.svg"} aria-label="App Core" width={100} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">React Native</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/flutter-logo.svg"} aria-label="App Core" width={70} height={48} className="img-fluid" style={{ marginTop: 5 }} alt />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Flutter</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="cloud-tab" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_60">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="claim-grid-group">
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-life-buoy" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">01</h6>
                                                                    {/* <h5 className="heading">Sound advice</h5> */}
                                                                    {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                                    <img src={"/assets/images/Symbol/azure.svg"} aria-label="App Core" width={100} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">Azure</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/aws.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 20 }} alt />
                                                                    <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">AWS</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="database-tab" role="tabpanel">
                                        <div className="tab-history-wrap section-space--mt_60">

                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="claim-grid-group">
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-one">
                                                                <div className="icon">
                                                                    <span className="icon-basic-life-buoy" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">01</h6>
                                                                    {/* <h5 className="heading">Sound advice</h5> */}
                                                                    {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                                    <img src={"/assets/images/Symbol/microsoft-sql-server.svg"} aria-label="App Core" width={130} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                                    <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">MS SQL</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-two">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/mysql.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 13 }} alt />
                                                                    <h5 style={{ marginTop: 13 }}><span className="text-color-primary sub-heading">My Sql</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/postgresql.svg"} aria-label="App Core" width={65} height={48} className="img-fluid" style={{ marginTop: 18 }} alt />
                                                                    <h5 style={{ marginTop: 18 }}><span className="text-color-primary sub-heading">Postgres</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-four">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/MongoDB_Fores-Green.svg.png"} aria-label="App Core" width={160} height={48} className="img-fluid" style={{ marginTop: 30 }} alt />
                                                                    <h5 style={{ marginTop: 30 }}><span className="text-color-primary sub-heading">Mongo DB</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-five">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/Azure_Cosmos_DB.svg.png"} aria-label="App Core" width={120} height={48} className="img-fluid" style={{ marginTop: 2 }} alt />
                                                                    <h5 style={{ marginTop: 2 }}><span className="text-color-primary sub-heading">Azure Cosmos</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="ht-box-icon style-04">
                                                            <div className="icon-box-wrap color-three">
                                                                <div className="icon">
                                                                    <span className="icon-basic-anticlockwise" />
                                                                </div>
                                                                <div className="content">
                                                                    <h6 className="sub-heading">02</h6>
                                                                    {/* <h5 className="heading">Prompt help</h5> */}
                                                                    <img src={"/assets/images/Symbol/firebase.svg"} aria-label="App Core" width={70} height={48} className="img-fluid" style={{ marginTop: 5 }} alt />
                                                                    <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Firebase</span></h5>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row row--10">
                                <div className="col-lg-8">
                                    <div className="success-stories-wrap mt-40">
                                        <div className="modern-number-01 me-5">
                                            <h2 className="heading"><span className="mark-text">15</span></h2>
                                            <p>YEARS IN THE FIELD</p>
                                        </div>
                                        <div className="content">
                                            <h4 className="heading mb-20">Read further to <br /> our Success <br /> Stories</h4>
                                            <a href="#" className="ht-btn ht-btn-md"><i className="far fa-comment-alt me-2" /> Let's talk</a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="modern-it-newsletters-wrap text-center mt-40">
                                        <div className="section-title">
                                            <h6 className="heading mb-30">Don’t miss our monthly newsletters with the best analyses.</h6>
                                        </div>
                                        <form action="#">
                                            <div className="contact-inner">
                                                <input name="con_email" type="email" placeholder="Your e-mail" />
                                            </div>
                                            <div className="button-wrap mb-15">
                                                <a href="#" className="ht-btn ht-btn-md ht-btn-full">Subscribe</a>
                                            </div>
                                            <p>Join 100 subscribers.</p>
                                        </form>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                    {/*===========  Projects wrapper End =============*/}
                    {/*====================  testimonial section ====================*/}
                    <div className="testimonial-slider-area section-space--ptb_120 bg-gray-3">
                        <div className="container-fluid container-fluid--cp-80">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title-wrap text-center section-space--mb_60">
                                        <h6 className="section-sub-title mb-20">Testimonials</h6>
                                        <h3 className="heading">Why do people praise about<span className="text-color-primary"> AppCore Innocations?</span></h3>
                                    </div>
                                    <div className="testimonial-slider">
                                        <div className="swiper-container testimonial-slider__container-two">
                                            <div className="swiper-wrapper testimonial-slider__wrapper">
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__single wow move-up">
                                                        <h6 className="testimonial-subject">Transformative Solutions and Exceptional Service</h6>
                                                        <div className="testimonial-slider__text">AppCore Innovations transformed our business with a custom software solution. The project was on time, within budget, and their support was exceptional. Highly recommended!. </div>
                                                        <div className="author-info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-04-70x70.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <h6 className="name">Mahesh Gurjar</h6>
                                                                <span className="designation">Co-Founder - MG Infosoft</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__single wow move-up">
                                                        <h6 className="testimonial-subject">Innovative and Professional Team</h6>
                                                        <div className="testimonial-slider__text">AppCore Innovations exceeded our expectations with a web application that improved our operations. The team was professional, responsive, and great to work with.</div>
                                                        <div className="author-info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-02-70x70.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <h6 className="name">Sandip Suthar</h6>
                                                                <span className="designation">Operational Head</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__single wow move-up">
                                                        <h6 className="testimonial-subject">Reliable and Efficient Solutions</h6>
                                                        <div className="testimonial-slider__text">AppCore Innovations delivered a reliable solution that streamlined our processes and boosted productivity. We're very pleased with the results.</div>
                                                        <div className="author-info">
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-01-70x70.webp" className="img-fluid" alt />
                                                            </div>
                                                            <div className="testimonial-slider__author">
                                                                <h6 className="name">Pujan Bhavasar</h6>
                                                                <span className="designation">Shree Lakshi Narayan Food Industry - Director</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="testimonial-slider__single wow move-up">
                                                        <h6 className="testimonial-subject">Outstanding Customer Support</h6>
                                                        <div className="testimonial-slider__text">AppCore Innovations+ support was outstanding and made a big difference. We look forward to continuing our partnership. </div>
                                                        <div className="author-info" style={{ marginTop: 55 }}>
                                                            <div className="testimonial-slider__media">
                                                                <img src="assets/images/testimonial/mitech-testimonial-avata-03-70x70.webp" className="img-fluid" alt />
                                                            </div>

                                                            <div className="testimonial-slider__author" >
                                                                <h6 className="name">Milan Joshi</h6>
                                                                <span className="designation">Project Manager</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="swiper-pagination swiper-pagination-t0 section-space--mt_40" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*====================  End of testimonial section  ====================*/}
                    {/*====================  Blog Section Start ====================*/}


                    {/* Blogs */}



                    {/*====================  Blog Section End  ====================*/}
                    {/*====================  Conact us Section Start ====================*/}
                    {/* <div className="contact-us-section-wrappaer infotechno-contact-us-bg section-space--ptb_120">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-lg-6 col-lg-6">
                                    <div className="conact-us-wrap-one">
                                        <h3 className="heading">Obtaining further information by <span className="text-color-primary">make a contact</span> with our experienced IT staffs. </h3>
                                        <div className="sub-heading">We’re available for 8 hours a day!<br />Contact to require a detailed analysis and assessment of your plan.</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-lg-6">
                                    <div className="contact-info-one text-center">
                                        <div className="icon">
                                            <span className="fas fa-phone" />
                                        </div>
                                        <h2 className="call-us"><a href="tel:9998855249">Reach out now!</a></h2>
                                        <div className="contact-us-button mt-20">
                                            <a href="#" className="btn btn--secondary">Contact us</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/*====================  Conact us Section End  ====================*/}
                </div>

            </div>

            {/*====================  search overlay ====================*/}
            <div className="search-overlay" id="search-overlay">
                <div className="search-overlay__header">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-md-6 ms-auto col-4">
                                {/* search content */}
                                <div className="search-content text-end">
                                    <span className="mobile-navigation-close-icon" id="search-close-trigger" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="search-overlay__inner">
                    <div className="search-overlay__body">
                        <div className="search-overlay__form">
                            <form action="#">
                                <input type="text" placeholder="Search" />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {/*====================  End of search overlay  ====================*/}
        </>
    );
}

export default App;
