import React from 'react';


const Technologies = () => {
    return (
        <>

            {/* breadcrumb-area start */}
            <div className="breadcrumb-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb_box text-center">
                                <h2 className="breadcrumb-title">Technologies</h2>
                                {/* breadcrumb-list start */}
                                <ul className="breadcrumb-list">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item active">Technologies</li>
                                </ul>
                                {/* breadcrumb-list end */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* breadcrumb-area end */}
            {/*===========  Projects wrapper Start =============*/}
            <div className="projects-wrapper projectinfotechno-bg section-space--ptb_100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12" >
                            {/* section-title-wrap Start */}
                            <div className="section-title-wrap text-center section-space--mb_40">
                                <h6 className="section-sub-title mb-20">Our Tech Stack</h6>
                                <h3 className="heading">Technologies We <span className="text-color-primary"> Specialize In</span></h3>
                            </div>
                            {/* section-title-wrap Start */}
                        </div>
                    </div>

                    <div>
                        <div className="col-lg-12 ht-tab-wrap">
                            <div className="row">
                                <div className="col-12 text-center wow move-up ">
                                    <ul className="nav justify-content-center ht-tab-menu" role="tablist">
                                        <li className="tab__item nav-item active">
                                            <a className="nav-link active" id="nav-tab1" data-bs-toggle="tab" href="#frontend-tab" role="tab" aria-selected="true">Front-End</a>
                                        </li>
                                        <li className="tab__item nav-item">
                                            <a className="nav-link" id="nav-tab2" data-bs-toggle="tab" href="#backend-tab" role="tab" aria-selected="false">Backend</a>
                                        </li>
                                        <li className="tab__item nav-item ">
                                            <a className="nav-link" id="nav-tab3" data-bs-toggle="tab" href="#mobile-tab" role="tab" aria-selected="false">Mobile</a>
                                        </li>
                                        <li className="tab__item nav-item ">
                                            <a className="nav-link" id="nav-tab5" data-bs-toggle="tab" href="#cloud-tab" role="tab" aria-selected="false">Cloud</a>
                                        </li>
                                        <li className="tab__item nav-item ">
                                            <a className="nav-link" id="nav-tab5" data-bs-toggle="tab" href="#database-tab" role="tab" aria-selected="false">Database</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="tab-content ht-tab__content wow move-up" style={{ textAlign: 'center' }}>
                            <div className="tab-pane fade show active" id="frontend-tab" role="tabpanel">
                                <div className="tab-history-wrap section-space--mt_60">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="claim-grid-group ">
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-life-buoy" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">01</h6>
                                                            {/* <h5 className="heading">Sound advice</h5> */}
                                                            {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                            <img src={"/assets/images/Symbol/nextjs.png"} aria-label="App Core" width={125} height={48} className="img-fluid" alt style={{ marginTop: 43 }} />
                                                            <h5 style={{ marginTop: 43 }}><span className="text-color-primary sub-heading"> Next JS</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/react.svg"} aria-label="App Core" width={90} height={48} className="img-fluid" alt />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> React Js</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-three">
                                                        <div className="icon">
                                                            <span className="icon-basic-world" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">03</h6>
                                                            <img src={"/assets/images/Symbol/angular.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" alt />
                                                            <h5 style={{ marginTop: 2 }}><span className="text-color-primary sub-heading">Angular</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-four">
                                                        <div className="icon">
                                                            <span className="icon-basic-world" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">04</h6>
                                                            <img src={"/assets/images/Symbol/js.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt />
                                                            <h5 style={{ marginTop: 6 }}><span className="text-color-primary sub-heading"> Javascript</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-five">
                                                        <div className="icon">
                                                            <span className="icon-basic-case" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">05</h6>
                                                            <img src={"/assets/images/Symbol/jquery1.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 5 }} />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> Jquery</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-five">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">06</h6>
                                                            <img src={"/assets/images/Symbol/ts.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading"> Type Scrtipt</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-four">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">07</h6>
                                                            <img src={"/assets/images/Symbol/html.png"} aria-label="App Core" width={180} height={48} className="img-fluid" alt style={{ marginTop: 2 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">HTML</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">08</h6>
                                                            <img src={"/assets/images/Symbol/css.png"} aria-label="App Core" width={85} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                            <h5 style={{ marginTop: 0 }}><span className="text-color-primary sub-heading">CSS</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-three">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">09</h6>
                                                            <img src={"/assets/images/Symbol/bootstrap.svg"} aria-label="App Core" width={120} height={48} className="img-fluid" alt style={{ marginTop: 0 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">Bootstrop</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">10</h6>
                                                            <img src={"/assets/images/Symbol/tailwindcss.svg"} aria-label="App Core" width={130} height={48} className="img-fluid" alt style={{ marginTop: 15 }} />
                                                            <h5 style={{ marginTop: 15 }}><span className="text-color-primary sub-heading">Tailwind CSS</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-lock" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">11</h6>
                                                            <img src={"/assets/images/Symbol/figma.svg"} aria-label="App Core" width={130} height={48} className="img-fluid" alt style={{ marginTop: 30 }} />
                                                            <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">Figma</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="backend-tab" role="tabpanel">
                                <div className="tab-history-wrap section-space--mt_60">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="claim-grid-group">
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-life-buoy" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">01</h6>
                                                            {/* <h5 className="heading">Sound advice</h5> */}
                                                            {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                            <img src={"/assets/images/Symbol/dotnet.svg"} aria-label="App Core" width={95} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">.Net</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/csharp.svg"} aria-label="App Core" width={95} height={48} className="img-fluid" alt />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">C#</span></h5>

                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-three">
                                                        <div className="icon">
                                                            <span className="icon-basic-world" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">03</h6>
                                                            <img src={"/assets/images/Symbol/nodejs.svg"} aria-label="App Core" width={120} height={48} className="img-fluid" style={{ marginTop: 20 }} alt />
                                                            <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">Node Js</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-four">
                                                        <div className="icon">
                                                            <span className="icon-basic-world" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">04</h6>
                                                            <img src={"/assets/images/Symbol/express.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 41 }} alt />
                                                            <h5 style={{ marginTop: 41 }}><span className="text-color-primary sub-heading">Express Js</span></h5>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-five">
                                                        <div className="icon">
                                                            <span className="icon-basic-case" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">05</h6>
                                                            <img src={"/assets/images/Symbol/graphql.png"} aria-label="App Core" width={100} height={48} className="img-fluid" alt style={{ marginTop: 5 }} />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Graph QL</span></h5>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="mobile-tab" role="tabpanel">
                                <div className="tab-history-wrap section-space--mt_60">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="claim-grid-group">
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-life-buoy" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">01</h6>
                                                            {/* <h5 className="heading">Sound advice</h5> */}
                                                            {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                            <img src={"/assets/images/Symbol/react-native.svg"} aria-label="App Core" width={100} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">React Native</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/flutter-logo.svg"} aria-label="App Core" width={70} height={48} className="img-fluid" style={{ marginTop: 5 }} alt />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Flutter</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="cloud-tab" role="tabpanel">
                                <div className="tab-history-wrap section-space--mt_60">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="claim-grid-group">
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-life-buoy" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">01</h6>
                                                            {/* <h5 className="heading">Sound advice</h5> */}
                                                            {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                            <img src={"/assets/images/Symbol/azure.svg"} aria-label="App Core" width={100} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">Azure</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/aws.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 20 }} alt />
                                                            <h5 style={{ marginTop: 20 }}><span className="text-color-primary sub-heading">AWS</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="database-tab" role="tabpanel">
                                <div className="tab-history-wrap section-space--mt_60">

                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="claim-grid-group">
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-one">
                                                        <div className="icon">
                                                            <span className="icon-basic-life-buoy" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">01</h6>
                                                            {/* <h5 className="heading">Sound advice</h5> */}
                                                            {/* <p className="text">Knowledge come with age and time. Our experts make sure it works.</p> */}
                                                            <img src={"/assets/images/Symbol/microsoft-sql-server.svg"} aria-label="App Core" width={130} height={30} className="img-fluid" alt style={{ marginTop: 10 }} />
                                                            <h5 style={{ marginTop: 10 }}><span className="text-color-primary sub-heading">MS SQL</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-two">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/mysql.svg"} aria-label="App Core" width={100} height={48} className="img-fluid" style={{ marginTop: 13 }} alt />
                                                            <h5 style={{ marginTop: 13 }}><span className="text-color-primary sub-heading">My Sql</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-three">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/postgresql.svg"} aria-label="App Core" width={65} height={48} className="img-fluid" style={{ marginTop: 15 }} alt />
                                                            <h5 style={{ marginTop: 15 }}><span className="text-color-primary sub-heading">Postgres</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-four">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/MongoDB_Fores-Green.svg.png"} aria-label="App Core" width={160} height={48} className="img-fluid" style={{ marginTop: 28 }} alt />
                                                            <h5 style={{ marginTop: 28 }}><span className="text-color-primary sub-heading">Mongo DB</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-five">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/Azure_Cosmos_DB.svg.png"} aria-label="App Core" width={130} height={48} className="img-fluid" style={{ marginTop: 5 }} alt />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Azure Cosmos</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="ht-box-icon style-04">
                                                    <div className="icon-box-wrap color-three">
                                                        <div className="icon">
                                                            <span className="icon-basic-anticlockwise" />
                                                        </div>
                                                        <div className="content">
                                                            <h6 className="sub-heading">02</h6>
                                                            {/* <h5 className="heading">Prompt help</h5> */}
                                                            <img src={"/assets/images/Symbol/firebase.svg"} aria-label="App Core" width={70} height={48} className="img-fluid" style={{ marginTop: 5 }} alt />
                                                            <h5 style={{ marginTop: 5 }}><span className="text-color-primary sub-heading">Firebase</span></h5>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="row row--10">
                        <div className="col-lg-8">
                            <div className="success-stories-wrap mt-40">
                                <div className="modern-number-01 me-5">
                                    <h2 className="heading"><span className="mark-text">15</span></h2>
                                    <p>YEARS IN THE FIELD</p>
                                </div>
                                <div className="content">
                                    <h4 className="heading mb-20">Read further to <br /> our Success <br /> Stories</h4>
                                    <a href="#" className="ht-btn ht-btn-md"><i className="far fa-comment-alt me-2" /> Let's talk</a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="modern-it-newsletters-wrap text-center mt-40">
                                <div className="section-title">
                                    <h6 className="heading mb-30">Don’t miss our monthly newsletters with the best analyses.</h6>
                                </div>
                                <form action="#">
                                    <div className="contact-inner">
                                        <input name="con_email" type="email" placeholder="Your e-mail" />
                                    </div>
                                    <div className="button-wrap mb-15">
                                        <a href="#" className="ht-btn ht-btn-md ht-btn-full">Subscribe</a>
                                    </div>
                                    <p>Join 100 subscribers.</p>
                                </form>
                            </div>
                        </div>
                    </div> */}


                </div>
            </div>
            {/*===========  Projects wrapper End =============*/}
        </>

    );
};

export default Technologies;