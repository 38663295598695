import React from 'react';
const ContactUs = () => {
    return (
        <>

            <div>
                {/* breadcrumb-area start */}
                <div className="breadcrumb-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <div className="breadcrumb_box text-center">
                                    <h2 className="breadcrumb-title">Careers</h2>
                                    {/* breadcrumb-list start */}
                                    <ul className="breadcrumb-list">
                                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                                        <li className="breadcrumb-item active">Careers</li>
                                    </ul>
                                    {/* breadcrumb-list end */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* breadcrumb-area end */}
                <div id="main-wrapper">
                    <div className="site-wrapper-reveal">
                        {/*======== careers-experts Start ==========*/}
                        <div className="careers-experts-wrapper section-space--pt_100">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-7 m-auto">
                                        {/* section-title-wrap Start */}
                                        <div className="section-title-wrap text-center section-space--mb_30">
                                            <h3 className="heading">Become a part of our big family to inspire and get inspired by <span className="text-color-primary"> professional experts.</span></h3>
                                        </div>
                                        {/* section-title-wrap Start */}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="ht-simple-job-listing move-up animate">
                                            <div clas="list">
                                                <div className="item">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="job-info" style={{ marginTop: 21 }}>
                                                                <h5 className="job-name">.Net Developer</h5>
                                                                <span className="job-time">Full time</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="job-description">
                                                                <div className="job-info">
                                                                    <span className="job-time"><b>Experience:</b> &nbsp;  2 to 7 Years</span>
                                                                    <span className="job-time"><b>Skills:</b> &nbsp;
                                                                        C#, EF Core , MVC , Web API , Graph QL,Microsoft Services</span>
                                                                    <span className="job-time"><b> Vacancies:</b> &nbsp;  1</span>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="job-button text-md-center">
                                                                <a className="ht-btn ht-btn-md ht-btn--solid" >
                                                                    <span className="button-text">Get started </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="job-info" style={{ marginTop: 21 }}>
                                                                <h5 className="job-name">React Js Developer</h5>
                                                                <span className="job-time">Full time</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="job-description">
                                                                <div className="job-info">
                                                                    <span className="job-time"><b>Experience:</b> &nbsp;  2 to 3 Years</span>
                                                                    <span className="job-time"><b>Skills:</b> &nbsp;
                                                                        React JS, Redux, Redux Saga, Webpack, Javascript, Typescript</span>
                                                                    <span className="job-time"><b> Vacancies:</b> &nbsp;  2</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="job-button text-md-center">
                                                                <a className="ht-btn ht-btn-md ht-btn--solid" >
                                                                    <span className="button-text">Get started </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="job-info" style={{ marginTop: 21 }}>
                                                                <h5 className="job-name">React Native Developer</h5>
                                                                <span className="job-time">Full time</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="job-description">
                                                                <div className="job-info">
                                                                    <span className="job-time"><b>Experience:</b> &nbsp;  2 to 5 Years</span>
                                                                    <span className="job-time"><b>Skills:</b> &nbsp;
                                                                        React native, Redux, Jquery, Javascript, Typescript</span>
                                                                    <span className="job-time"><b> Vacancies:</b> &nbsp;  1</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="job-button text-md-center">
                                                                <a className="ht-btn ht-btn-md ht-btn--solid" >
                                                                    <span className="button-text">Get started </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="item">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <div className="job-info" style={{ marginTop: 21 }}>
                                                                <h5 className="job-name">Flutter Developer</h5>
                                                                <span className="job-time">Full time</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="job-description">
                                                                <div className="job-info" >
                                                                    <span className="job-time"><b>Experience:</b> &nbsp;  2 to 5 Years</span>
                                                                    <span className="job-time"><b>Skills:</b> &nbsp;
                                                                        Flutter , Dart</span>
                                                                    <span className="job-time"><b> Vacancies:</b> &nbsp;  0</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="job-button text-md-center">
                                                                <a className="ht-btn ht-btn-md ht-btn--solid" >
                                                                    <span className="button-text">Get started </span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="contact-us-section-wrappaer infotechno-contact-us-bg section-space--ptb_120" style={{ marginTop: 100 }}>
                            <div className="container">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 col-lg-6">
                                        <div className="conact-us-wrap-one">
                                            <h3 className="heading">Obtaining further information by <span className="text-color-primary">make a contact</span> with our experienced IT staffs. </h3>
                                            <div className="sub-heading">We’re available for 8 hours a day!<br />Contact to require a detailed analysis and assessment of your plan.</div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-lg-6">
                                        <div className="contact-info-one text-center">
                                            <div className="icon">
                                                <span className="fas fa-phone" />
                                            </div>
                                            <h6 className="heading font-weight--reguler call-us"><a href="tel:9998855249">Reach out now!</a></h6>
                                            {/* <h4 className="call-us"><a href="tel:9998855249">(+91) 999 885 524 9</a></h4> */}
                                            <div className="contact-us-button mt-20">
                                                <a className="btn btn--secondary">Contact us</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*====================  Conact us Section End  ====================*/}
                    </div>

                </div>
            </div>


        </>

    );
};

export default ContactUs;