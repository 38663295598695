import { within } from "@testing-library/react";
import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

function MasterLayout({ children }) {
    const [menu, setMenu] = useState("mobile-menu-overlay")
    const [showScrollUp, setShowScrollUp] = useState(false);
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [isSticky, setIsSticky] = useState(false);

    const openMenu = () => {
        setMenu("mobile-menu-overlay active")
    }

    const navigate = useNavigate();

    const handleClick = () => {
        window.location = ' /about-us'
    };

    useEffect(() => {
        const handleScroll = () => {
            const scroll = window.scrollY;
            const header = document.querySelector('.header-sticky');
            if (header) {
                const headerHeight = header.offsetHeight;
                if (window.innerWidth >= 320) {
                    if (scroll < headerHeight) {
                        setIsSticky(false);
                    } else {
                        setIsSticky(true);
                    }
                }
            }
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > lastScrollTop) {
                setShowScrollUp(false);
            } else {
                setShowScrollUp(scrollTop > 200);
            }
            setLastScrollTop(scrollTop);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [lastScrollTop]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };


    return (
        <>

            <div className="preloader-activate preloader-active open_tm_preloader">
                <div className="preloader-area-wrap">
                    <div className="spinner d-flex justify-content-center align-items-center h-100">
                        <div className="bounce1" />
                        <div className="bounce2" />
                        <div className="bounce3" />
                    </div>
                </div>
            </div>

            <div className="header-area header-area--default">
                {/* Header Top Wrap Start */}
                {/* <div className="header-top-wrap border-bottom">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-lg-12">
                                    <p className="text-center top-message"><a href="#">Now Hiring:</a> Are you a driven and motivated 1st Line IT Support Engineer?</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* Header Top Wrap End */}
                {/* Header Bottom Wrap Start */}
                <div className={`header-bottom-wrap header-sticky ${isSticky ? 'is-sticky' : ''}`}>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header default-menu-style position-relative">
                                    {/* brand logo */}
                                    <div className="header__logo">
                                        <a href="/">
                                            <img src="assets/images/logo/dark-logo.png" aria-label="App Core" className="img-fluid" style={{ width: 210 }} alt />
                                        </a>
                                    </div>
                                    {/* header midle box  */}
                                    <div className="header-midle-box">
                                        <div className="header-bottom-wrap d-md-block d-none">
                                            <div className="header-bottom-inner">
                                                <div className="header-bottom-left-wrap">
                                                    {/* navigation menu */}
                                                    <div className="header__navigation d-none d-xl-block">
                                                        <nav className="navigation-menu primary--menu">
                                                            <ul className="menu-font-weight" style={{ textAlign: "start" }}>
                                                                <li className="">
                                                                    <a href="/"><span>Home</span></a>
                                                                </li>
                                                                <li className="has-children has-children--multilevel-submenu ">
                                                                    <a href="#"><span>Services</span></a>
                                                                    <ul className="submenu">
                                                                        <li><a className="menu-font-weight" href="#"><span>Web App Development</span></a></li>
                                                                        <li><a className="menu-font-weight" href="#"><span>Mobile App Development</span></a></li>
                                                                        <li><a className="menu-font-weight" href="#"><span>Cross Platform App Development</span></a></li>
                                                                        <li><a className="menu-font-weight" href="#"><span>UI/UX Development</span></a></li>
                                                                    </ul>
                                                                </li>

                                                                <li className="has-children">
                                                                    <a href="#"><span>Technologies</span></a>
                                                                    <ul className="megamenu megamenu--mega">
                                                                        <li>
                                                                            <img src={"/assets/images/banner/1.gif"} width={300} alt="Services" />

                                                                        </li>

                                                                        <li>
                                                                            <h2 className="page-list-title" style={{ marginLeft: -15 }}> <img src={"/assets/images/Symbol/sort/frontend.webp"} width={35} alt="Services" /> &nbsp; Front End</h2>
                                                                            <ul>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/next-js.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span >Next Js</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/react-js.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>React Js</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/vite.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>ViteJS</span></a></li>
                                                                                <li><a href="#">
                                                                                    <img src={"/assets/images/Symbol/sort/anjuear.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Angular</span></a></li>
                                                                                <li><a>
                                                                                    <img src={"/assets/images/Symbol/js.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Javascript &amp; Jquery </span></a></li>
                                                                                <li><a>
                                                                                    <img src={"/assets/images/Symbol/ts.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Type Script</span></a></li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            <h2 className="page-list-title" style={{ marginLeft: -15 }}><img src={"/assets/images/Symbol/sort/backend1.png"} width={35} alt="Services" />&nbsp;  Backend End</h2>
                                                                            <ul>
                                                                                <li><a>
                                                                                    <img src={"/assets/images/Symbol/sort/netcore.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>.Net</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/nodejs.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Node</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/expressjs.webp"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Express Js</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/graphql.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>GraphQL</span></a></li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            <h2 className="page-list-title" style={{ marginLeft: -15 }}><img src={"/assets/images/Symbol/sort/mobile.png"} width={35} alt="Services" />&nbsp;  Mobile</h2>
                                                                            <ul>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/reactnative.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>React Native</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/flutter-logo.svg"} width={23} alt="Services" /> &nbsp;
                                                                                    <span>Flutter</span></a></li>
                                                                                {/* <li><a href="#"><span>Ionic Framework</span></a></li> */}
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/apple-logo.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>IOS</span></a></li>
                                                                                {/* <li><a href="#"><span>Swift</span></a></li> */}
                                                                                {/* <li><a href="#"><span>Kotlin</span></a></li> */}
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/sort/android.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Android</span></a></li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            <h2 className="page-list-title" style={{ marginLeft: -15 }}><img src={"/assets/images/Symbol/sort/cloud1.png"} width={35} alt="Services" />&nbsp;  Cloud</h2>
                                                                            <ul>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/azure.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>Azure</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/aws.svg"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>AWS</span></a></li>
                                                                                <li><a >
                                                                                    <img src={"/assets/images/Symbol/iot.png"} width={25} alt="Services" /> &nbsp;
                                                                                    <span>IOT Applications</span></a></li>
                                                                            </ul>
                                                                        </li>

                                                                    </ul>
                                                                </li>
                                                                <li className="has-children has-children--multilevel-submenu">
                                                                    <a href="#"><span>Company</span></a>
                                                                    <ul className="submenu">
                                                                        <li><a className="menu-font-weight" onClick={handleClick}><span>About us</span> </a></li>
                                                                        <li><a className="menu-font-weight" href="/contact-us"><span>Contact us</span></a></li>
                                                                        <li><a className="menu-font-weight" href="/why-choose-us"><span>Why Choose us</span></a></li>
                                                                        <li><a className="menu-font-weight" href="/careers"><span>Careers</span></a></li>
                                                                        <li><a className="menu-font-weight" href="/cookies-policy"><span>Cookies Policy</span></a></li>
                                                                    </ul>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* header right box */}
                                    <div className="header-right-box">
                                        <div className="header-right-inner" id="hidden-icon-wrapper">
                                            {/* language-menu */}
                                            {/* <div className="language-menu">
                                                    <ul>
                                                        <li>
                                                            <a href="#" className>
                                                                <img className="ls-flag" src="assets/images/flags/en.webp" alt="en" title="English" />
                                                                <span className="wpml-ls-native">English</span>
                                                            </a>
                                                            <ul className="ls-sub-menu">
                                                                <li className>
                                                                    <a href="#" className>
                                                                        <img className="ls-flag" src="assets/images/flags/fr.webp" alt="en" title="Français" /><span className="wpml-ls-native">Français</span>
                                                                    </a>
                                                                </li>
                                                                <li className>
                                                                    <a href="#" className>
                                                                        <img className="wpml-ls-flag" src="assets/images/flags/de.webp" alt="de" title="Deutsch" /><span className="wpml-ls-native">Deutsch</span>
                                                                    </a>
                                                                </li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            {/* header-search-form */}
                                            {/* <div className="header-search-form default-search">
                                                    <form action="#" className="search-form-top">
                                                        <input className="search-field" type="text" placeholder="Search…" />
                                                        <button className="search-submit">
                                                            <i className="search-btn-icon fa fa-search" />
                                                        </button>
                                                    </form>
                                                </div> */}

                                            <div className="">
                                                <div className="text-center" style={{ fontSize: 25, color: 'rgb(100,177,166)' }}>
                                                    <div className="icon">
                                                        <span className="fas fa-phone" />
                                                    </div>
                                                    <h6 className="call-us"><a href="tel:9998855249">Reach out now!</a></h6>
                                                </div>
                                            </div>

                                        </div>
                                        {/* mobile menu */}
                                        <div className="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger" onClick={() => openMenu()}>
                                            {/* <div className="contact-info-one text-center" > */}
                                            <div className=" text-center" >
                                                <div className="call-us icon">
                                                    <a href="tel:9998855249"> <span className="fas fa-phone" style={{ fontSize: 25, color: 'rgb(100,177,166)' }} /></a>
                                                </div>
                                                {/* <h6 className="call-us"><a href="tel:9998855249">Reach out now!</a></h6> */}
                                            </div>
                                        </div>
                                        <div className="mobile-navigation-icon d-block d-xl-none" id="mobile-menu-trigger" onClick={() => openMenu()}>
                                            <i />
                                        </div>
                                        {/* hidden icons menu */}
                                        <div className="hidden-icons-menu d-block d-md-none" id="hidden-icon-trigger">
                                            <a href="javascript:void(0)">
                                                <i className="far fa-ellipsis-h-alt" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Header Bottom Wrap End */}
            </div>

            <div>{children}</div>

            {/*====================  scroll top ====================*/}
            <a href="#"
                id="scroll-top"
                className={`scroll-top ${showScrollUp ? 'show' : ''}`}
                onClick={scrollToTop}
            >
                <i className="arrow-top fas fa-chevron-up" />
                <i className="arrow-bottom fas fa-chevron-up" />
            </a>
            {/*====================  End of scroll top  ====================*/}


            {/*====================  footer area ====================*/}
            <div className="footer-area-wrapper bg-gray">
                <div className="footer-area section-space--ptb_80">
                    <div className="container" style={{ textAlign: "start" }}>
                        <div className="row footer-widget-wrapper">
                            <div className="col-lg-4 col-md-6 col-sm-6 footer-widget">
                                <div className="footer-widget__logo mb-30">
                                    <img src="assets/images/logo/logo-dark.png" className="img-fluid" style={{ width: 200 }} alt />
                                </div>
                                <ul className="footer-widget__list">
                                    <li>28,2nd Floor R3 The Mall,</li>
                                    <li>Opp. Manav Mandir, Gurukul Road, Memnagar,</li>
                                    <li>Ahmedabad, Gujarat, India.</li>
                                    <li><a className="hover-style-link text-color-primary">info@appcoreinnovations.com</a></li>
                                    <li><a href="tel:9998855249" className="hover-style-link text-black font-weight--bold">(+91) 999 885 524 9</a></li>
                                    <li><a href="https://appcoreinnovations.com/" className="hover-style-link text-color-primary">www.appcoreinnovations.com</a></li>
                                </ul>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget" >
                                <h6 className="footer-widget__title mb-20">IT Services</h6>
                                <ul className="footer-widget__list" >
                                    <li><a href="#" className="hover-style-link">Web App Development</a></li>
                                    <li><a href="#" className="hover-style-link">Mobile App Development</a></li>
                                    <li><a href="#" className="hover-style-link">Cross Platfrom App Development</a></li>
                                    <li><a href="#" className="hover-style-link">UI/UX Development</a></li>
                                </ul>
                            </div>
                            {/* <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                        <h6 className="footer-widget__title mb-20">Quick links</h6>
                                        <ul className="footer-widget__list">
                                            <li><a href="#" className="hover-style-link">Pick up locations</a></li>
                                            <li><a href="#" className="hover-style-link">Terms of Payment</a></li>
                                            <li><a href="#" className="hover-style-link">Privacy Policy</a></li>
                                            <li><a href="#" className="hover-style-link">Where to Find Us</a></li>
                                        </ul>
                                    </div> */}
                            <div className="col-lg-2 col-md-4 col-sm-6 footer-widget" >
                                <h6 className="footer-widget__title mb-20">Support</h6>
                                <ul className="footer-widget__list">
                                    <li><a onClick={handleClick} className="hover-style-link">About Us</a></li>
                                    <li><a href="/contact-us" className="hover-style-link">Contact Us</a></li>
                                    <li><a href="/why-choose-us" className="hover-style-link">Why choose us</a></li>
                                    <li><a href="/careers" className="hover-style-link">Careers</a></li>
                                    <li><a href="/cookies-policy" className="hover-style-link">Cookies Policy</a></li>

                                </ul>
                            </div>

                            {/* <div className="col-lg-2 col-md-4 col-sm-6 footer-widget">
                                        <div className="footer-widget__title section-space--mb_50" />
                                        <ul className="footer-widget__list">
                                            <li><a href="#" className="image_btn" aria-label="Google play Button"><img className="img-fluid" src="assets/images/icons/aeroland-button-google-play.webp" alt /></a></li>
                                            <li><a href="#" className="image_btn" aria-label="App Store Button"><img className="img-fluid" src="assets/images/icons/aeroland-button-app-store.webp" alt /></a></li>
                                        </ul>
                                    </div> */}
                        </div>
                    </div>
                </div>
                <div className="footer-copyright-area section-space--pb_30">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6 text-center text-md-start">
                                <span className="copyright-text">© 2024 Appcore Innocations. <a href="https://appcoreinnovations.com/">All Rights Reserved.</a></span>
                            </div>
                            {/* <div className="col-md-6 text-center text-md-end">
                                        <ul className="list ht-social-networks solid-rounded-icon">
                                            <li className="item">
                                                <a href="https://twitter.com" target="_blank" aria-label="Twitter" className="social-link hint--bounce hint--top hint--primary">
                                                    <i className="fab fa-twitter link-icon" />
                                                </a>
                                            </li>
                                            <li className="item">
                                                <a href="https://facebook.com" target="_blank" aria-label="Facebook" className="social-link hint--bounce hint--top hint--primary">
                                                    <i className="fab fa-facebook-f link-icon" />
                                                </a>
                                            </li>
                                            <li className="item">
                                                <a href="https://instagram.com" target="_blank" aria-label="Instagram" className="social-link hint--bounce hint--top hint--primary">
                                                    <i className="fab fa-instagram link-icon" />
                                                </a>
                                            </li>
                                            <li className="item">
                                                <a href="https://linkedin.com" target="_blank" aria-label="Linkedin" className="social-link hint--bounce hint--top hint--primary">
                                                    <i className="fab fa-linkedin link-icon" />
                                                </a>
                                            </li>
                                        </ul>
                                    </div> */}
                        </div>
                    </div>
                </div>
            </div>
            {/*====================  End of footer area  ====================*/}

            {/*====================  mobile menu overlay ====================*/}
            <div className={menu} id="mobile-menu-overlay">
                <div className="mobile-menu-overlay__inner">
                    <div className="mobile-menu-overlay__header">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-8">
                                    {/* logo */}
                                    <div className="logo">
                                        <a href="index.html">
                                            <img src="assets/images/logo/dark-logo.png" width={185} className="img-fluid" alt />
                                        </a>
                                    </div>
                                </div>
                                <div className="col-md-6 col-4">
                                    {/* mobile menu content */}
                                    <div className="mobile-menu-content text-end">
                                        <span className="mobile-navigation-close-icon" id="mobile-menu-close-trigger" onClick={() => { setMenu("mobile-menu-overlay") }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mobile-menu-overlay__body">
                        <nav className="offcanvas-navigation">
                            <ul>
                                <li className="has-children">
                                    <a href="/">Home</a>
                                </li>
                                <li className="has-children">
                                    <a href="#">Services</a>
                                    <ul className="sub-menu">
                                        <li><a ><span>Web App Development</span></a></li>
                                        <li><a ><span>Mobile App Development</span></a></li>
                                        <li><a ><span>Cross Platform App Development</span></a></li>
                                        <li><a ><span>UI/UX Development</span></a></li>

                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href="#">Company</a>
                                    <ul className="sub-menu">
                                        <li><a href="/about-us"><span>About Us</span></a></li>
                                        <li><a href="/contact-us"><span>Contact Us</span></a></li>
                                        <li><a href="/why-choose-us"><span>Why choose us</span></a></li>
                                        <li><a href="/careers"><span>Careers</span></a></li>
                                        <li><a href="/cookies-policy"><span>Cookies Policy</span></a></li>
                                    </ul>
                                </li>
                                <li className="has-children">
                                    <a href="/technologies">Technologies We Specialize In </a>
                                    {/* <ul className="sub-menu">
                                        <li className="has-children">
                                            <a href="#">Element Group 01</a>
                                            <ul className="sub-menu">
                                                <li><a href="element-accordion.html"><span>Accordions &amp; Toggles</span></a></li>
                                                <li><a href="element-box-icon.html"><span>Box Icon</span></a></li>
                                                <li><a href="element-box-image.html"><span>Box Image</span></a></li>
                                                <li><a href="element-box-large-image.html"><span>Box Large Image</span></a></li>
                                                <li><a href="element-buttons.html"><span>Buttons</span></a></li>
                                                <li><a href="element-cta.html"><span>Call to action</span></a></li>
                                                <li><a href="element-client-logo.html"><span>Client Logo</span></a></li>
                                                <li><a href="element-countdown.html"><span>Countdown</span></a></li>
                                            </ul>
                                        </li>
                                        <li className="has-children">
                                            <a href="#">Element Group 02</a>
                                            <ul className="sub-menu">
                                                <li><a href="element-counters.html"><span>Counters</span></a></li>
                                                <li><a href="element-dividers.html"><span>Dividers</span></a></li>
                                                <li><a href="element-flexible-image-slider.html"><span>Flexible image slider</span></a></li>
                                                <li><a href="element-google-map.html"><span>Google Map</span></a></li>
                                                <li><a href="element-gradation.html"><span>Gradation</span></a></li>
                                                <li><a href="element-instagram.html"><span>Instagram</span></a></li>
                                                <li><a href="element-lists.html"><span>Lists</span></a></li>
                                                <li><a href="element-message-box.html"><span>Message box</span></a></li>
                                            </ul>
                                        </li>
                                        <li className="has-children">
                                            <a href="#">Element Group 03</a>
                                            <ul className="sub-menu">
                                                <li><a href="element-popup-video.html"><span>Popup Video</span></a></li>
                                                <li><a href="element-pricing-box.html"><span>Pricing Box</span></a></li>
                                                <li><a href="element-progress-bar.html"><span>Progress Bar</span></a></li>
                                                <li><a href="element-progress-circle.html"><span>Progress Circle</span></a></li>
                                                <li><a href="element-rows-columns.html"><span>Rows &amp; Columns</span></a></li>
                                                <li><a href="element-social-networks.html"><span>Social Networks</span></a></li>
                                                <li><a href="element-tabs.html"><span>Tabs</span></a></li>
                                                <li><a href="element-team-member.html"><span>Team member</span></a></li>
                                            </ul>
                                        </li>
                                        <li className="has-children">
                                            <a href="#">Element Group 04</a>
                                            <ul className="sub-menu">
                                                <li><a href="element-testimonials.html"><span>Testimonials</span></a></li>
                                                <li><a href="element-timeline.html"><span>Timeline</span></a></li>
                                                <li><a href="element-carousel-sliders.html"><span>Carousel Sliders</span></a></li>
                                                <li><a href="element-typed-text.html"><span>Typed Text</span></a></li>
                                            </ul>
                                        </li>
                                    </ul> */}
                                </li>


                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            {/*====================  End of mobile menu overlay  ====================*/}



        </>
    );
}

export default MasterLayout;
