import React, { useState } from 'react';
import axios from 'axios';

const EmailForm = () => {
    const [to, setTo] = useState('maulik.suthar82@mail.com');
    const [subject, setSubject] = useState('Hello');
    const [text, setText] = useState('Hemoo My Name is maulik suthar');
    const [responseMessage, setResponseMessage] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await axios.post('http://localhost:5000/send-email', {
                to,
                subject,
                text
            });
            setResponseMessage('Email sent successfully!');
        } catch (error) {
            setResponseMessage('Failed to send email.');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div>
                <label>To:</label>
                <input
                    type="email"
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Subject:</label>
                <input
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Message:</label>
                <textarea
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                    required
                />
            </div>
            <button type="submit">Send Email</button>
            <p>{responseMessage}</p>
        </form>
    );
};

export default EmailForm;